import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
/**
 * 重写路由的push方法(解决多次点击同一个菜单路由跳转，会报错)
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error);
};
/**
 * 重写路由的replace方法*（解决notfound以后重新回到上一页同样会报错。）
 */
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/privacy',
            name: 'privacyAgreement',
            meta: {
                title: '隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/xxz/privacyAgreement.vue')
        },
        {
            path: '/user',
            name: 'userAgreement',
            meta: {
                title: '用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/xxz/userAgreement.vue')
        },
        {
            path: '/business',
            name: 'businessApp',
            meta: {
                title: '闲侠赚商家版',
                hideInMenu: true
            },
            component: () => import('@/view/xxz/businessApp.vue')
        },
        {
            path: '/hubAgreement',
            name: 'hubAgreement',
            meta: {
                title: '闲侠赚活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/xxz/hubAgreement.vue')
        },
        {
            path: '/taozhipin/hubAgreement',
            name: 'taozhipinHubAgreement',
            meta: {
                title: '淘直聘活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/taozhipin/hubAgreement.vue')
        },
        {
            path: '/taozhipin/privacy',
            name: 'taozhipinPrivacyAgreement',
            meta: {
                title: '隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/taozhipin/privacyAgreement.vue')
        },
        {
            path: '/taozhipin/user',
            name: 'taozhipinUserAgreement',
            meta: {
                title: '用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/taozhipin/userAgreement.vue')
        },
        {
            path: '/taozhipin/business',
            name: 'taozhipinBusinessApp',
            meta: {
                title: '淘直聘商家版',
                hideInMenu: true
            },
            component: () => import('@/view/taozhipin/businessApp.vue')
        },
        {
            path: '/xianxiashe/privacy',
            name: 'xianxiashePrivacyAgreement',
            meta: {
                title: '隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/xianxiashe/privacyAgreement.vue')
        },
        {
            path: '/xianxiashe/user',
            name: 'xianxiasheUserAgreement',
            meta: {
                title: '用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/xianxiashe/userAgreement.vue')
        },
        {
            path: '/xianxiashe/hubAgreement',
            name: 'xianxiasheHubAgreement',
            meta: {
                title: '闲侠社活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/xianxiashe/hubAgreement.vue')
        },
        {
            path: '/kjz/privacy',
            name: 'kjzPrivacyAgreement',
            meta: {
                title: '快兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/kjz/privacyAgreement.vue')
        },
        {
            path: '/kjz/user',
            name: 'kjzUserAgreement',
            meta: {
                title: '快兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/kjz/userAgreement.vue')
        },
        {
            path: '/kjz/hubAgreement',
            name: 'kjzHubAgreement',
            meta: {
                title: '快兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/kjz/hubAgreement.vue')
        },
        {
            path: '/jrl/hubAgreement',
            name: 'jrlHubAgreement',
            meta: {
                title: '今日结活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/jrl/hubAgreement.vue')
        },
        {
            path: '/jrl/privacy',
            name: 'jrlPrivacyAgreement',
            meta: {
                title: '今日结隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/jrl/privacyAgreement.vue')
        },
        {
            path: '/jrl/user',
            name: 'jrlUserAgreement',
            meta: {
                title: '今日结用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/jrl/userAgreement.vue')
        },
        {
            path: '/msj/hubAgreement',
            name: 'msjHubAgreement',
            meta: {
                title: '马上结活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/msj/hubAgreement.vue')
        },
        {
            path: '/msj/privacy',
            name: 'msjPrivacyAgreement',
            meta: {
                title: '马上结隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/msj/privacyAgreement.vue')
        },
        {
            path: '/msj/user',
            name: 'msjUserAgreement',
            meta: {
                title: '马上结用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/msj/userAgreement.vue')
        },
        {
            path: '/youfuye/hubAgreement',
            name: 'youfuyeHubAgreement',
            meta: {
                title: '有副业活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/youfuye/hubAgreement.vue')
        },
        {
            path: '/youfuye/privacy',
            name: 'youfuyePrivacyAgreement',
            meta: {
                title: '有副业隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/youfuye/privacyAgreement.vue')
        },
        {
            path: '/youfuye/user',
            name: 'youfuyeUserAgreement',
            meta: {
                title: '有副业用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/youfuye/userAgreement.vue')
        },

        {
            path: '/haha/hubAgreement',
            name: 'hahaHubAgreement',
            meta: {
                title: '哈哈活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/haha/hubAgreement.vue')
        },
        {
            path: '/haha/privacy',
            name: 'hahaPrivacyAgreement',
            meta: {
                title: '哈哈隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/haha/privacyAgreement.vue')
        },
        {
            path: '/haha/user',
            name: 'hahaUserAgreement',
            meta: {
                title: '哈哈结用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/haha/userAgreement.vue')
        },
        {
            path: '/shandian/hubAgreement',
            name: 'shandianHubAgreement',
            meta: {
                title: '闪电兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/shandian/hubAgreement.vue')
        },
        {
            path: '/shandian/privacy',
            name: 'shandianPrivacyAgreement',
            meta: {
                title: '闪电兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/shandian/privacyAgreement.vue')
        },
        {
            path: '/shandian/user',
            name: 'shandianUserAgreement',
            meta: {
                title: '闪电兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/shandian/userAgreement.vue')
        },
        {
            path: '/youqiantu/hubAgreement',
            name: 'youqiantuHubAgreement',
            meta: {
                title: '有前途兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/youqiantu/hubAgreement.vue')
        },
        {
            path: '/youqiantu/privacy',
            name: 'youqiantuPrivacyAgreement',
            meta: {
                title: '有前途兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/youqiantu/privacyAgreement.vue')
        },
        {
            path: '/youqiantu/user',
            name: 'youqiantuUserAgreement',
            meta: {
                title: '有前途兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/youqiantu/userAgreement.vue')
        },
        {
            path: '/xmf/hubAgreement',
            name: 'xmfHubAgreement',
            meta: {
                title: '小蜜蜂兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/xmf/hubAgreement.vue')
        },
        {
            path: '/xmf/privacy',
            name: 'xmfPrivacyAgreement',
            meta: {
                title: '小蜜蜂兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/xmf/privacyAgreement.vue')
        },
        {
            path: '/xmf/user',
            name: 'xmfUserAgreement',
            meta: {
                title: '小蜜蜂兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/xmf/userAgreement.vue')
        },
        {
            path: '/zzz/hubAgreement',
            name: 'zzzHubAgreement',
            meta: {
                title: '赚赚赚兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/zzz/hubAgreement.vue')
        },
        {
            path: '/zzz/privacy',
            name: 'zzzPrivacyAgreement',
            meta: {
                title: '赚赚赚兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/zzz/privacyAgreement.vue')
        },
        {
            path: '/zzz/user',
            name: 'zzzUserAgreement',
            meta: {
                title: '赚赚赚兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/zzz/userAgreement.vue')
        },
        {
            path: '/huosujie/hubAgreement',
            name: 'huosujieHubAgreement',
            meta: {
                title: '火速结兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/huosujie/hubAgreement.vue')
        },
        {
            path: '/huosujie/privacy',
            name: 'huosujiePrivacyAgreement',
            meta: {
                title: '火速结兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/huosujie/privacyAgreement.vue')
        },
        {
            path: '/huosujie/user',
            name: 'huosujieUserAgreement',
            meta: {
                title: '火速结兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/huosujie/userAgreement.vue')
        }, {
            path: '/ttz/hubAgreement',
            name: 'ttzHubAgreement',
            meta: {
                title: '天天赚兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/ttz/hubAgreement.vue')
        },
        {
            path: '/ttz/privacy',
            name: 'ttzPrivacyAgreement',
            meta: {
                title: '天天赚兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/ttz/privacyAgreement.vue')
        },
        {
            path: '/ttz/user',
            name: 'ttzjieUserAgreement',
            meta: {
                title: '天天赚兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/ttz/userAgreement.vue')
        },
        {
            path: '/chaogeili/hubAgreement',
            name: 'chaogeiliHubAgreement',
            meta: {
                title: '超给力兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/chaogeili/hubAgreement.vue')
        },
        {
            path: '/chaogeili/privacy',
            name: 'chaogeiliPrivacyAgreement',
            meta: {
                title: '超给力兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/chaogeili/privacyAgreement.vue')
        },
        {
            path: '/chaogeili/user',
            name: 'chaogeilijieUserAgreement',
            meta: {
                title: '超给力兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/chaogeili/userAgreement.vue')
        },
        {
            path: '/jsj/hubAgreement',
            name: 'jsjHubAgreement',
            meta: {
                title: '急速结兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/jsj/hubAgreement.vue')
        },
        {
            path: '/jsj/privacy',
            name: 'jsjPrivacyAgreement',
            meta: {
                title: '急速结兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/jsj/privacyAgreement.vue')
        },
        {
            path: '/jsj/user',
            name: 'jsjjieUserAgreement',
            meta: {
                title: '急速结兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/jsj/userAgreement.vue')
        },

        {
            path: '/kjz-hw/privacy',
            name: 'kjzhwPrivacyAgreement',
            meta: {
                title: '快兼职隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/kjz-hw/privacyAgreement.vue')
        },
        {
            path: '/kjz-hw/user',
            name: 'kjzhwUserAgreement',
            meta: {
                title: '快兼职用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/kjz-hw/userAgreement.vue')
        },
        {
            path: '/kjz-hw/hubAgreement',
            name: 'kjzhwHubAgreement',
            meta: {
                title: '快兼职活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/kjz-hw/hubAgreement.vue')
        },
        {
            path: '/skz/privacy',
            name: 'skzPrivacyAgreement',
            meta: {
                title: '时刻赚隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/skz/privacyAgreement.vue')
        },
        {
            path: '/skz/user',
            name: 'skzUserAgreement',
            meta: {
                title: '时刻赚用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/skz/userAgreement.vue')
        },
        {
            path: '/skz/hubAgreement',
            name: 'skzAgreement',
            meta: {
                title: '时刻赚活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/skz/hubAgreement.vue')
        },
        {
            path: '/skz/shareList',
            name: 'shareList',
            meta: {
                title: '时刻赚个人信息共享清单',
                hideInMenu: true
            },
            component: () => import('@/view/skz/shareList.vue')
        },
        {
            path: '/skz/collectList',
            name: 'collectList',
            meta: {
                title: '时刻赚个人信息收集清单',
                hideInMenu: true
            },
            component: () => import('@/view/skz/collectList.vue')
        },
        {
            path: '/jinrilai/hubAgreement',
            name: 'jinrilaiHubAgreement',
            meta: {
                title: '今日来活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/jinrilai/hubAgreement.vue')
        },
        {
            path: '/jinrilai/privacy',
            name: 'jinrilaiPrivacyAgreement',
            meta: {
                title: '今日来隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/jinrilai/privacyAgreement.vue')
        },
        {
            path: '/jinrilai/user',
            name: 'jinrilaiUserAgreement',
            meta: {
                title: '今日来用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/jinrilai/userAgreement.vue')
        },
        {
            path: '/jzkx/privacy',
            name: 'jzkxPrivacyAgreement',
            meta: {
                title: '兼职快线隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/jzkx/privacyAgreement.vue')
        },
        {
            path: '/jzkx/user',
            name: 'jzkxUserAgreement',
            meta: {
                title: '兼职快线用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/jzkx/userAgreement.vue')
        },
        {
            path: '/jzkx/hubAgreement',
            name: 'jzkxAgreement',
            meta: {
                title: '兼职快线活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/jzkx/hubAgreement.vue')
        },
        {
            path: '/jzkx/shareList',
            name: 'shareList',
            meta: {
                title: '兼职快线个人信息共享清单',
                hideInMenu: true
            },
            component: () => import('@/view/jzkx/shareList.vue')
        },
        {
            path: '/jzkx/collectList',
            name: 'collectList',
            meta: {
                title: '兼职快线个人信息收集清单',
                hideInMenu: true
            },
            component: () => import('@/view/jzkx/collectList.vue')
        },



        {
            path: '/mmz/privacy',
            name: 'mmzPrivacyAgreement',
            meta: {
                title: '每秒赚隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/mmz/privacyAgreement.vue')
        },
        {
            path: '/mmz/user',
            name: 'mmzUserAgreement',
            meta: {
                title: '每秒赚用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/mmz/userAgreement.vue')
        },
        {
            path: '/mmz/hubAgreement',
            name: 'mmzAgreement',
            meta: {
                title: '每秒赚活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/mmz/hubAgreement.vue')
        },
        {
            path: '/mmz/shareList',
            name: 'shareList',
            meta: {
                title: '每秒赚个人信息共享清单',
                hideInMenu: true
            },
            component: () => import('@/view/mmz/shareList.vue')
        },
        {
            path: '/mmz/collectList',
            name: 'collectList',
            meta: {
                title: '每秒赚个人信息收集清单',
                hideInMenu: true
            },
            component: () => import('@/view/mmz/collectList.vue')
        },




        {
            path: '/srwjz/privacy',
            name: 'srwjzPrivacyAgreement',
            meta: {
                title: '收入王隐私协议',
                hideInMenu: true
            },
            component: () => import('@/view/srwjz/privacyAgreement.vue')
        },
        {
            path: '/srwjz/user',
            name: 'srwjzUserAgreement',
            meta: {
                title: '收入王用户协议',
                hideInMenu: true
            },
            component: () => import('@/view/srwjz/userAgreement.vue')
        },
        {
            path: '/srwjz/hubAgreement',
            name: 'srwjzAgreement',
            meta: {
                title: '收入王活动规则',
                hideInMenu: true
            },
            component: () => import('@/view/srwjz/hubAgreement.vue')
        },
        {
            path: '/srwjz/shareList',
            name: 'shareList',
            meta: {
                title: '收入王个人信息共享清单',
                hideInMenu: true
            },
            component: () => import('@/view/srwjz/shareList.vue')
        },
        {
            path: '/srwjz/collectList',
            name: 'collectList',
            meta: {
                title: '收入王个人信息收集清单',
                hideInMenu: true
            },
            component: () => import('@/view/srwjz/collectList.vue')
        },
    ]
});
// 路由前置导航守卫
router.beforeEach((to, from, next) => {
    // 根据路由元信息设置文档标题
    window.document.title = to.meta.title;
    next();
});
export default router;
